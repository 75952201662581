<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
        <path fill="#4285F4"
            d="M29.4 15.34c0-1.063-.095-2.085-.273-3.067H15v5.809h8.073c-.355 1.868-1.419 3.45-3.014 4.513v3.778h4.868c2.837-2.619 4.473-6.464 4.473-11.032Z"
        />
        <path fill="#34A853"
            d="M15 30c4.05 0 7.445-1.336 9.927-3.627l-4.868-3.777c-1.337.9-3.041 1.445-5.06 1.445-3.9 0-7.213-2.632-8.4-6.177H1.61v3.872C4.077 26.633 9.136 30 14.999 30Z"
        />
        <path fill="#FBBC05"
            d="M6.6 17.85c-.3-.9-.477-1.855-.477-2.85 0-.995.177-1.95.477-2.85V8.277H1.61A14.815 14.815 0 0 0 0 15c0 2.427.586 4.705 1.61 6.723l3.885-3.027L6.6 17.85Z"
        />
        <path fill="#EA4335"
            d="M15 5.973c2.209 0 4.172.763 5.74 2.236l4.296-4.295C22.432 1.486 19.05 0 15 0 9.136 0 4.077 3.368 1.609 8.277l4.99 3.873c1.187-3.545 4.5-6.177 8.4-6.177Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
